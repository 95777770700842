.mainContent {
    background: #161617;
    color: #eceeed;
    padding: 0px 50px;
}

.foundLine {
    padding-bottom: 15px;
}

.itemLink {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}

.searchError {
    font-size: 18px;
    padding-top: 20px;
    font-weight: bold;

}