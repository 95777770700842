.header {
    background: #242323;
    border-bottom: 1px solid;
    color: #fcfcfc;
    height: 46px;
    line-height: 1;
    padding: 8px 16px;
    position: sticky;
    top: 0;
    z-index: 10;
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.title a {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    color: inherit;
}

.inputs {
    align-items: center;
    color: hsla(0,0%,100%,.75);
    display: flex;
    font-size: 12px;
    height: 24px;
    justify-content: center;
    line-height: 1;
    padding: 0 6px;
}

.searchBox {
    align-items: center;
    background-color: hsla(0,0%,100%,.1);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsl(0deg 0% 100% / 20%);
    color: hsla(0,0%,100%,.75);
    display: flex;
    font-size: 12px;
    height: 24px;
    justify-content: center;
    line-height: 1;
    padding: 0 6px;
    transition: all .15s ease-in-out;
}

    .searchBox .searchInput {
        all: unset;
        color: hsla(0,0%,100%,.95);
        font-family: inherit;
        font-size: 14px;
        margin-left: 8px;
        width: 180px;
    }

.filterBox {
    align-items: center;
    background-color: hsla(0,0%,100%,.1);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsl(0deg 0% 100% / 20%);
    display: flex;
    height: 24px;
    padding: 0 6px;
    transition: all .15s ease-in-out;
    margin-right: 20px;
}

    .filterBox select {
        background-color: hsla(0,0%,100%,0);
        color: hsla(0,0%,100%,.95);
        font-family: inherit;
        font-size: 14px;
        margin-left: 8px;
        width: 100px;
        border: none;
    }

        .filterBox select option {
            all: unset;
            background: #242323;
            color: hsla(0,0%,100%,.95);
            font-family: inherit;
            font-size: 14px;
            margin-left: 8px;
            width: 180px;
        }